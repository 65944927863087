<script setup>
import Button from '@/Components/Button/Button.vue';
import DataCard from '@/Components/DataCard.vue';
import Array from '@/Components/Input/Array.vue';
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');

const props = defineProps({
    company: Object,
});

const form = useForm({
    ...props.company,
});

const sendForm = () => {
    form.put(route('staff.companies.update', { company: props.company.id }), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            form.errors = mapErrors(errors);
        },
    });
};
</script>
<template>
    <DataCard :title="$t('{model} details', { model: $t('Company') })">
        <FormKit type="form" id="companyUpdateForm" @submit="sendForm" :actions="false">
            <div class="grid gap-x-8 md:grid-cols-2">
                <FormKit
                    type="text"
                    name="coc"
                    :label="$t('CoC')"
                    v-model="form.kvk_number"
                    :errors="form.errors.kvk_number"
                    :value="form.kvk_number"
                />

                <FormKit
                    type="text"
                    name="vat_number"
                    :label="$t('VAT number')"
                    v-model="form.vat_number"
                    :errors="form.errors.vat_number"
                    :value="form.vat_number"
                    :help="$t('Please apply the following format: “NL 123 456 789 B01”')"
                />
                <FormKit
                    type="tel"
                    name="phone_number"
                    :label="$t('Phone')"
                    v-model="form.phone_number"
                    :errors="form.errors.phone_number"
                    :value="form.phone_number"
                />
                <FormKit
                    type="email"
                    name="email"
                    :label="$t('E-mail')"
                    v-model="form.email"
                    :errors="form.errors.email"
                    :value="form.email"
                />

                <FormKit
                    type="text"
                    name="bic"
                    :label="$t('BIC')"
                    v-model="form.bic"
                    :errors="form.errors.bic"
                    :value="form.bic"
                />

                <FormKit
                    type="text"
                    name="iban"
                    :label="$t('IBAN')"
                    v-model="form.iban"
                    :errors="form.errors.iban"
                    :value="form.iban"
                />
            </div>
            <Button
                admin
                :processing="form.processing"
                :recentlySuccessful="form.recentlySuccessful"
                :hasErrors="Object.values(form.errors).length > 0"
                icon="/icon/done-white.svg"
                :text="$t('Save')"
            />
        </FormKit>
    </DataCard>
</template>
